var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "my-5",
    attrs: {
      "fluid": _vm.$vuetify.breakpoint.lgAndDown && true
    }
  }, [_vm._t("table-header"), _c('v-data-table', _vm._g(_vm._b({
    staticClass: "rounded-lg elevation-0",
    class: ["elevation-".concat(_vm.elevation, " ").concat(_vm.isEditable ? 'row-pointer' : '')],
    attrs: {
      "height": !_vm.isCommonView ? '100%' : _vm.state.isItemsPerPageDefault ? '53vh' : '70vh',
      "fixed-header": _vm.isCommonView,
      "footer-props": {
        'items-per-page-options': [10, 50, 100]
      }
    },
    on: {
      "update:items-per-page": function updateItemsPerPage($event) {
        return _vm.listeners.onUpdateItemsPerPage($event);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$attrs.headers, function (header) {
      return {
        key: "header.".concat(header.value),
        fn: function fn(slotProps) {
          return [_vm._t("header.".concat(header.value), function () {
            return [_c('span', {
              key: header.value
            }, [_vm._v(_vm._s(slotProps.header.text))])];
          }, null, slotProps)];
        }
      };
    }), {
      key: "item.entity",
      fn: function fn(slotProps) {
        return [_vm._t("entity", null, null, slotProps)];
      }
    }, {
      key: "item.entityId",
      fn: function fn(slotProps) {
        return [_vm._t("entityId", null, null, slotProps)];
      }
    }, {
      key: "item.professionalUnits",
      fn: function fn(_ref) {
        var item = _ref.item;
        return _vm._l(item.activeProfessionalUnits, function (profUnit) {
          return _c('span', {
            key: profUnit.id + Math.round(Math.random() * 1000)
          }, [_c('v-chip', {
            staticClass: "my-1 mr-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(profUnit.name) + " ")])], 1);
        });
      }
    }, {
      key: "item.assignments",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return _vm._l(item.assignments, function (assignment) {
          return _c('span', {
            key: assignment.id
          }, [_c('v-chip', {
            staticClass: "my-1 mr-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(assignment.name) + " ")])], 1);
        });
      }
    }, {
      key: "item.currentlyAssigned",
      fn: function fn(slotProps) {
        return [_vm._t("currentlyAssigned", null, null, slotProps)];
      }
    }, {
      key: "item.type",
      fn: function fn(slotProps) {
        return [_vm._t("type", null, null, slotProps)];
      }
    }, {
      key: "item.profiles",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return _vm._l(item.activeAssignmentProfiles, function (profile) {
          return _c('span', {
            key: profile.id + Math.round(Math.random() * 1000)
          }, [_c('v-chip', {
            staticClass: "my-1 mr-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(profile.name) + " ")])], 1);
        });
      }
    }, {
      key: "item.approvals",
      fn: function fn(slotProps) {
        return [_vm._t("approvals", function () {
          return _vm._l(slotProps === null || slotProps === void 0 ? void 0 : slotProps.item.approvals, function (approval) {
            return _c('span', {
              key: approval.id + Math.round(Math.random() * 1000)
            }, [_c('v-chip', {
              staticClass: "my-1 mr-1",
              attrs: {
                "small": ""
              }
            }, [_vm._v(" " + _vm._s(approval.approvedBy.name) + " ")])], 1);
          });
        }, null, slotProps)];
      }
    }, {
      key: "item.secondLevelProfessionalUnit",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', [_c('v-chip', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(item.secondLevelProfessionalUnit.name) + " ")])], 1)];
      }
    }, {
      key: "item.professionalUnit",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_c('v-chip', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(item.professionalUnit.name) + " ")])], 1)];
      }
    }, {
      key: "item.profile",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', [_c('v-chip', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(item.profile.name) + " ")])], 1)];
      }
    }, {
      key: "item.updatedAt",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.updatedAt)) + " ")];
      }
    }, {
      key: "item.createdAt",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.createdAt)) + " ")];
      }
    }, {
      key: "item.month",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.month)) + " ")];
      }
    }, {
      key: "item.resubmission",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.resubmission)) + " ")];
      }
    }, {
      key: "item.solvedOn",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.solvedOn)) + " ")];
      }
    }, {
      key: "item.dueTo",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.dueTo)) + " ")];
      }
    }, {
      key: "item.issuedOn",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.issuedOn)) + " ")];
      }
    }, {
      key: "item.birthdate",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.birthdate)) + " ")];
      }
    }, {
      key: "item.firstContacted",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.firstContacted)) + " ")];
      }
    }, {
      key: "item.start",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.start)) + " ")];
      }
    }, {
      key: "item.end",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.end)) + " ")];
      }
    }, {
      key: "item.inService",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.inService)) + " ")];
      }
    }, {
      key: "item.outOfService",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.outOfService)) + " ")];
      }
    }, {
      key: "item.contractStart",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.contractStart)) + " ")];
      }
    }, {
      key: "item.contractEnd",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.contractEnd)) + " ")];
      }
    }, {
      key: "item.deadline",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.deadline)) + " ")];
      }
    }, {
      key: "item.offerPrice",
      fn: function fn(_ref23) {
        var item = _ref23.item;
        return [_vm._v(" " + _vm._s(_vm.functions.convertToEuro(item.offerPrice)) + " ")];
      }
    }, {
      key: "item.hourlyRate",
      fn: function fn(_ref24) {
        var item = _ref24.item;
        return [_vm._v(" " + _vm._s(_vm.functions.convertToEuro(item.hourlyRate)) + " ")];
      }
    }, {
      key: "item.interviewDate",
      fn: function fn(slotProps) {
        return [_vm._t("interviewDate", null, null, slotProps)];
      }
    }, {
      key: "item.scope",
      fn: function fn(_ref25) {
        var item = _ref25.item;
        return [_vm._v(" " + _vm._s(_vm.functions.convertDotIntoCommaInput(item.scope, 2)) + " ")];
      }
    }, {
      key: "item.capacity",
      fn: function fn(slotProps) {
        return [_vm._t("capacity", null, null, slotProps)];
      }
    }, _vm._l(_vm.state.capacityCols, function (capacityCol) {
      return {
        key: "header.".concat(capacityCol.value),
        fn: function fn(slotProps) {
          return [_vm._t("header.".concat(capacityCol.value), null, null, slotProps)];
        }
      };
    }), _vm._l(_vm.state.capacityCols, function (capacityCol) {
      return {
        key: "item.".concat(capacityCol.value),
        fn: function fn(slotProps) {
          return [_vm._t("item.".concat(capacityCol.value), null, null, slotProps)];
        }
      };
    }), {
      key: "item.overallUtilizationRelative",
      fn: function fn(_ref26) {
        var item = _ref26.item;
        return [_vm._v(" " + _vm._s(_vm.functions.convertDotIntoCommaInput(Number(item.overallUtilizationRelative.toFixed(1)))) + " ")];
      }
    }, {
      key: "item.inventoryItemStatus",
      fn: function fn(slotProps) {
        return [_vm._t("inventory-item-status", null, null, slotProps)];
      }
    }, {
      key: "item.contracts",
      fn: function fn(slotProps) {
        return [_vm._t("contracts", null, null, slotProps)];
      }
    }, {
      key: "item.contractRelationships",
      fn: function fn(_ref27) {
        var item = _ref27.item;
        return _vm._l(item.activeContractualRelationships, function (relationship, index) {
          return _c('span', {
            key: index + Math.round(Math.random() * 1000)
          }, [_c('v-chip', {
            staticClass: "my-1 mr-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(relationship) + " ")])], 1);
        });
      }
    }, {
      key: "item.complianceCheck",
      fn: function fn(_ref28) {
        var item = _ref28.item;
        return [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.complianceCheck)) + " ")];
      }
    }, {
      key: "item.compliancePoints",
      fn: function fn(slotProps) {
        return [_vm._t("compliance-points", null, null, slotProps)];
      }
    }, {
      key: "item.compliancePeriodDate",
      fn: function fn(slotProps) {
        return [_vm._t("compliance-period-date", null, null, slotProps)];
      }
    }, {
      key: "item.personScope",
      fn: function fn(slotProps) {
        return [_vm._t("personScope", null, null, slotProps)];
      }
    }, {
      key: "item.specialRoles",
      fn: function fn(slotProps) {
        return [_vm._t("specialRoles", null, null, slotProps)];
      }
    }, {
      key: "item.plannedPerson",
      fn: function fn(slotProps) {
        return [_vm._t("plannedPerson", null, null, slotProps)];
      }
    }, {
      key: "item.totalCapacityUtilizationRecorded",
      fn: function fn(_ref29) {
        var item = _ref29.item;
        return [_c('v-progress-linear', {
          attrs: {
            "value": item.totalCapacityUtilizationRecorded / item.totalCapacityUtilizationPlanned * 100,
            "color": "grey lighten-1",
            "rounded": "",
            "height": "25"
          }
        }, [_vm._v(" " + _vm._s(Number.isNaN(item.totalCapacityUtilizationRecorded / item.totalCapacityUtilizationPlanned) ? 0 : Math.round(item.totalCapacityUtilizationRecorded / item.totalCapacityUtilizationPlanned * 100)) + " % ")])];
      }
    }, {
      key: "item.deductionFactor",
      fn: function fn(slotProps) {
        return [_vm._t("deductionFactor", null, null, slotProps)];
      }
    }, {
      key: "item.capacityStatus",
      fn: function fn(slotProps) {
        return [_vm._t("capacityStatus", null, null, slotProps)];
      }
    }, {
      key: "item.count",
      fn: function fn(slotProps) {
        return [_vm._t("count", null, null, slotProps)];
      }
    }, {
      key: "item.enumItemAssignment",
      fn: function fn(slotProps) {
        return [_vm._t("enumItemAssignment", null, null, slotProps)];
      }
    }, {
      key: "item.active",
      fn: function fn(_ref30) {
        var item = _ref30.item;
        return [_c('DotIndicator', {
          attrs: {
            "color": "".concat(item.active ? 'green' : 'red')
          }
        })];
      }
    }, {
      key: "item.limited",
      fn: function fn(_ref31) {
        var item = _ref31.item;
        return [_c('DotIndicator', {
          attrs: {
            "color": "".concat(item.limited ? 'red' : 'green')
          }
        })];
      }
    }, {
      key: "item.positionStatus",
      fn: function fn(slotProps) {
        return [_vm._t("positionStatus", null, null, slotProps)];
      }
    }, {
      key: "item.positionId",
      fn: function fn(slotProps) {
        return [_vm._t("positionId", null, null, slotProps)];
      }
    }, {
      key: "item.contractualRelationships",
      fn: function fn(slotProps) {
        return [_vm._t("contractualRelationships", null, null, slotProps)];
      }
    }, {
      key: "item.sourcingType",
      fn: function fn(slotProps) {
        return [_vm._t("sourcingType", null, null, slotProps)];
      }
    }, {
      key: "item.sourcing",
      fn: function fn(slotProps) {
        return [_vm._t("sourcing", null, null, slotProps)];
      }
    }, {
      key: "item.roles",
      fn: function fn(slotProps) {
        return [_vm._t("roles", null, null, slotProps)];
      }
    }, {
      key: "item.professionalUnitsAsLeader",
      fn: function fn(_ref32) {
        var item = _ref32.item;
        return _vm._l(item.professionalUnitsAsLeader, function (professionalUnitAsLeader) {
          return _c('span', {
            key: professionalUnitAsLeader.id + Math.round(Math.random() * 1000)
          }, [_c('v-chip', {
            staticClass: "my-1 mr-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(professionalUnitAsLeader.name) + " ")])], 1);
        });
      }
    }, {
      key: "item.organizationalUnitsAsLeader",
      fn: function fn(_ref33) {
        var item = _ref33.item;
        return _vm._l(item.organizationalUnitsAsLeader, function (organizationalUnitAsLeader) {
          return _c('span', {
            key: organizationalUnitAsLeader.id + Math.round(Math.random() * 1000)
          }, [_c('v-chip', {
            staticClass: "my-1 mr-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(organizationalUnitAsLeader.name) + " ")])], 1);
        });
      }
    }, {
      key: "item.enabled",
      fn: function fn(_ref34) {
        var item = _ref34.item;
        return [_c('v-icon', [_vm._v(_vm._s(item.enabled ? _vm.icons.mdiCheck : _vm.icons.mdiClose))])];
      }
    }, _vm.withNotesAndTasks && (_vm.functions.hasSufficientRights(_vm.constants.Rights.TASK_READ_ALL) || _vm.functions.hasSufficientRights(_vm.constants.Rights.TASK_READ_OWN)) ? {
      key: "item.tasksCount",
      fn: function fn(_ref35) {
        var item = _ref35.item;
        return [_vm._v(" " + _vm._s(item.tasksCount) + " "), _vm.withNotesAndTasks ? _c('v-btn', {
          staticClass: "mx-1",
          attrs: {
            "icon": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$emit('click:tasks', item);
            }
          }
        }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiTextBoxCheckOutline) + " ")])], 1) : _vm._e()];
      }
    } : null, _vm.withNotesAndTasks && (_vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_READ_ALL) || _vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_READ_OWN)) ? {
      key: "item.notesCount",
      fn: function fn(_ref36) {
        var item = _ref36.item;
        return [_vm._v(" " + _vm._s(item.notesCount) + " "), _vm.withNotesAndTasks ? _c('v-btn', {
          staticClass: "mx-1",
          attrs: {
            "icon": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$emit('click:notes', item);
            }
          }
        }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiNotebookEditOutline) + " ")])], 1) : _vm._e()];
      }
    } : null, _vm.withFiles && _vm.functions.hasSufficientRights(_vm.constants.Rights.FILE_READ) ? {
      key: "item.filesCount",
      fn: function fn(_ref37) {
        var item = _ref37.item;
        return [_vm._v(" " + _vm._s(item.filesCount) + " "), _vm.withFiles ? _c('v-btn', {
          staticClass: "mx-1",
          attrs: {
            "icon": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$emit('click:files', item);
            }
          }
        }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPaperclip) + " ")])], 1) : _vm._e()];
      }
    } : null, {
      key: "item.actions",
      fn: function fn(slotProps) {
        return [_vm.withDuplicate ? _c('v-btn', {
          staticClass: "mx-1",
          attrs: {
            "icon": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$emit('click:duplicate', slotProps.item);
            }
          }
        }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiContentDuplicate) + " ")])], 1) : _vm._e(), _vm.withInfo ? _c('v-btn', {
          staticClass: "mx-1",
          attrs: {
            "icon": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$emit('click:info', slotProps.item);
            }
          }
        }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiInformationOutline) + " ")])], 1) : _vm._e(), _vm._t("item.actions.edit", function () {
          return [_vm.withEdit ? _c('v-btn', {
            staticClass: "mx-1",
            attrs: {
              "icon": "",
              "x-small": ""
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.$emit('click:edit', slotProps.item);
              }
            }
          }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")])], 1) : _vm._e()];
        }, null, slotProps), _vm.withDownload ? _c('v-btn', {
          staticClass: "mx-1",
          attrs: {
            "icon": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$emit('click:download', slotProps.item);
            }
          }
        }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDownload) + " ")])], 1) : _vm._e(), _vm._t("item.actions.delete", function () {
          return [_c('CommonDeleteIconBtn', {
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.$emit('click:delete', slotProps.item);
              }
            }
          })];
        }, null, slotProps), _vm._t("item.actions.options", null, null, slotProps)];
      }
    }], null, true)
  }, 'v-data-table', _vm.$attrs, false), _vm.$listeners)), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }